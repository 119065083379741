// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-card-add-card-success-tsx": () => import("./../../../src/pages/account/card/add-card-success.tsx" /* webpackChunkName: "component---src-pages-account-card-add-card-success-tsx" */),
  "component---src-pages-account-card-failure-tsx": () => import("./../../../src/pages/account/card/failure.tsx" /* webpackChunkName: "component---src-pages-account-card-failure-tsx" */),
  "component---src-pages-account-card-success-tsx": () => import("./../../../src/pages/account/card/success.tsx" /* webpackChunkName: "component---src-pages-account-card-success-tsx" */),
  "component---src-pages-account-reset-password-success-tsx": () => import("./../../../src/pages/account/reset-password-success.tsx" /* webpackChunkName: "component---src-pages-account-reset-password-success-tsx" */),
  "component---src-pages-account-reset-password-tsx": () => import("./../../../src/pages/account/reset-password.tsx" /* webpackChunkName: "component---src-pages-account-reset-password-tsx" */),
  "component---src-pages-account-verify-email-success-tsx": () => import("./../../../src/pages/account/verify-email-success.tsx" /* webpackChunkName: "component---src-pages-account-verify-email-success-tsx" */),
  "component---src-pages-account-verify-email-tsx": () => import("./../../../src/pages/account/verify-email.tsx" /* webpackChunkName: "component---src-pages-account-verify-email-tsx" */),
  "component---src-pages-admin-brands-tsx": () => import("./../../../src/pages/admin/brands.tsx" /* webpackChunkName: "component---src-pages-admin-brands-tsx" */),
  "component---src-pages-admin-content-tsx": () => import("./../../../src/pages/admin/content.tsx" /* webpackChunkName: "component---src-pages-admin-content-tsx" */),
  "component---src-pages-admin-credit-packages-index-tsx": () => import("./../../../src/pages/admin/credit-packages/index.tsx" /* webpackChunkName: "component---src-pages-admin-credit-packages-index-tsx" */),
  "component---src-pages-admin-dashboards-index-tsx": () => import("./../../../src/pages/admin/dashboards/index.tsx" /* webpackChunkName: "component---src-pages-admin-dashboards-index-tsx" */),
  "component---src-pages-admin-dashboards-key-performance-indicators-tsx": () => import("./../../../src/pages/admin/dashboards/key-performance-indicators.tsx" /* webpackChunkName: "component---src-pages-admin-dashboards-key-performance-indicators-tsx" */),
  "component---src-pages-admin-dashboards-session-frequencies-tsx": () => import("./../../../src/pages/admin/dashboards/session-frequencies.tsx" /* webpackChunkName: "component---src-pages-admin-dashboards-session-frequencies-tsx" */),
  "component---src-pages-admin-dashboards-sessions-tsx": () => import("./../../../src/pages/admin/dashboards/sessions.tsx" /* webpackChunkName: "component---src-pages-admin-dashboards-sessions-tsx" */),
  "component---src-pages-admin-dashboards-shipping-tsx": () => import("./../../../src/pages/admin/dashboards/shipping.tsx" /* webpackChunkName: "component---src-pages-admin-dashboards-shipping-tsx" */),
  "component---src-pages-admin-developer-tools-configuration-tsx": () => import("./../../../src/pages/admin/developer-tools/configuration.tsx" /* webpackChunkName: "component---src-pages-admin-developer-tools-configuration-tsx" */),
  "component---src-pages-admin-developer-tools-hangfire-tsx": () => import("./../../../src/pages/admin/developer-tools/hangfire.tsx" /* webpackChunkName: "component---src-pages-admin-developer-tools-hangfire-tsx" */),
  "component---src-pages-admin-developer-tools-tasks-tsx": () => import("./../../../src/pages/admin/developer-tools/tasks.tsx" /* webpackChunkName: "component---src-pages-admin-developer-tools-tasks-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-items-details-tsx": () => import("./../../../src/pages/admin/items/details.tsx" /* webpackChunkName: "component---src-pages-admin-items-details-tsx" */),
  "component---src-pages-admin-items-index-tsx": () => import("./../../../src/pages/admin/items/index.tsx" /* webpackChunkName: "component---src-pages-admin-items-index-tsx" */),
  "component---src-pages-admin-items-search-tsx": () => import("./../../../src/pages/admin/items/search.tsx" /* webpackChunkName: "component---src-pages-admin-items-search-tsx" */),
  "component---src-pages-admin-items-stats-tsx": () => import("./../../../src/pages/admin/items/stats.tsx" /* webpackChunkName: "component---src-pages-admin-items-stats-tsx" */),
  "component---src-pages-admin-marketing-tsx": () => import("./../../../src/pages/admin/marketing.tsx" /* webpackChunkName: "component---src-pages-admin-marketing-tsx" */),
  "component---src-pages-admin-message-threads-tsx": () => import("./../../../src/pages/admin/message-threads.tsx" /* webpackChunkName: "component---src-pages-admin-message-threads-tsx" */),
  "component---src-pages-admin-shipping-rates-tsx": () => import("./../../../src/pages/admin/shipping-rates.tsx" /* webpackChunkName: "component---src-pages-admin-shipping-rates-tsx" */),
  "component---src-pages-admin-stats-tsx": () => import("./../../../src/pages/admin/stats.tsx" /* webpackChunkName: "component---src-pages-admin-stats-tsx" */),
  "component---src-pages-admin-transactions-details-tsx": () => import("./../../../src/pages/admin/transactions/details.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-details-tsx" */),
  "component---src-pages-admin-transactions-financial-tsx": () => import("./../../../src/pages/admin/transactions/financial.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-financial-tsx" */),
  "component---src-pages-admin-transactions-index-tsx": () => import("./../../../src/pages/admin/transactions/index.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-index-tsx" */),
  "component---src-pages-admin-transactions-payouts-tsx": () => import("./../../../src/pages/admin/transactions/payouts.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-payouts-tsx" */),
  "component---src-pages-admin-transactions-shipping-provider-costs-tsx": () => import("./../../../src/pages/admin/transactions/shipping-provider-costs.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-shipping-provider-costs-tsx" */),
  "component---src-pages-admin-transactions-stats-tsx": () => import("./../../../src/pages/admin/transactions/stats.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-stats-tsx" */),
  "component---src-pages-admin-users-buyers-and-sellers-tsx": () => import("./../../../src/pages/admin/users/buyers-and-sellers.tsx" /* webpackChunkName: "component---src-pages-admin-users-buyers-and-sellers-tsx" */),
  "component---src-pages-admin-users-details-tsx": () => import("./../../../src/pages/admin/users/details.tsx" /* webpackChunkName: "component---src-pages-admin-users-details-tsx" */),
  "component---src-pages-admin-users-index-tsx": () => import("./../../../src/pages/admin/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-index-tsx" */),
  "component---src-pages-admin-users-purchase-log-entry-tsx": () => import("./../../../src/pages/admin/users/purchase-log-entry.tsx" /* webpackChunkName: "component---src-pages-admin-users-purchase-log-entry-tsx" */),
  "component---src-pages-admin-users-search-tsx": () => import("./../../../src/pages/admin/users/search.tsx" /* webpackChunkName: "component---src-pages-admin-users-search-tsx" */),
  "component---src-pages-admin-users-with-access-roles-tsx": () => import("./../../../src/pages/admin/users/with-access-roles.tsx" /* webpackChunkName: "component---src-pages-admin-users-with-access-roles-tsx" */),
  "component---src-pages-app-update-available-tsx": () => import("./../../../src/pages/app/update-available.tsx" /* webpackChunkName: "component---src-pages-app-update-available-tsx" */),
  "component---src-pages-credits-index-tsx": () => import("./../../../src/pages/credits/index.tsx" /* webpackChunkName: "component---src-pages-credits-index-tsx" */),
  "component---src-pages-credits-purchase-success-tsx": () => import("./../../../src/pages/credits/purchase-success.tsx" /* webpackChunkName: "component---src-pages-credits-purchase-success-tsx" */),
  "component---src-pages-documentation-animated-checkmark-tsx": () => import("./../../../src/pages/documentation/animated-checkmark.tsx" /* webpackChunkName: "component---src-pages-documentation-animated-checkmark-tsx" */),
  "component---src-pages-documentation-button-tsx": () => import("./../../../src/pages/documentation/button.tsx" /* webpackChunkName: "component---src-pages-documentation-button-tsx" */),
  "component---src-pages-documentation-card-tsx": () => import("./../../../src/pages/documentation/card.tsx" /* webpackChunkName: "component---src-pages-documentation-card-tsx" */),
  "component---src-pages-documentation-confirmation-page-tsx": () => import("./../../../src/pages/documentation/confirmation-page.tsx" /* webpackChunkName: "component---src-pages-documentation-confirmation-page-tsx" */),
  "component---src-pages-documentation-debounce-tsx": () => import("./../../../src/pages/documentation/debounce.tsx" /* webpackChunkName: "component---src-pages-documentation-debounce-tsx" */),
  "component---src-pages-documentation-file-upload-tsx": () => import("./../../../src/pages/documentation/file-upload.tsx" /* webpackChunkName: "component---src-pages-documentation-file-upload-tsx" */),
  "component---src-pages-documentation-item-card-grid-tsx": () => import("./../../../src/pages/documentation/item-card-grid.tsx" /* webpackChunkName: "component---src-pages-documentation-item-card-grid-tsx" */),
  "component---src-pages-documentation-item-card-tsx": () => import("./../../../src/pages/documentation/item-card.tsx" /* webpackChunkName: "component---src-pages-documentation-item-card-tsx" */),
  "component---src-pages-documentation-profile-picture-tsx": () => import("./../../../src/pages/documentation/profile-picture.tsx" /* webpackChunkName: "component---src-pages-documentation-profile-picture-tsx" */),
  "component---src-pages-documentation-progress-button-tsx": () => import("./../../../src/pages/documentation/progress-button.tsx" /* webpackChunkName: "component---src-pages-documentation-progress-button-tsx" */),
  "component---src-pages-documentation-question-mark-tooltip-tsx": () => import("./../../../src/pages/documentation/question-mark-tooltip.tsx" /* webpackChunkName: "component---src-pages-documentation-question-mark-tooltip-tsx" */),
  "component---src-pages-documentation-webp-image-tsx": () => import("./../../../src/pages/documentation/webp-image.tsx" /* webpackChunkName: "component---src-pages-documentation-webp-image-tsx" */),
  "component---src-pages-gavekort-index-tsx": () => import("./../../../src/pages/gavekort/index.tsx" /* webpackChunkName: "component---src-pages-gavekort-index-tsx" */),
  "component---src-pages-gavekort-succes-tsx": () => import("./../../../src/pages/gavekort/succes.tsx" /* webpackChunkName: "component---src-pages-gavekort-succes-tsx" */),
  "component---src-pages-handelsbetingelser-index-tsx": () => import("./../../../src/pages/handelsbetingelser/index.tsx" /* webpackChunkName: "component---src-pages-handelsbetingelser-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-items-details-tsx": () => import("./../../../src/pages/items/details.tsx" /* webpackChunkName: "component---src-pages-items-details-tsx" */),
  "component---src-pages-items-list-tsx": () => import("./../../../src/pages/items/list.tsx" /* webpackChunkName: "component---src-pages-items-list-tsx" */),
  "component---src-pages-items-object-to-report-success-tsx": () => import("./../../../src/pages/items/object-to-report-success.tsx" /* webpackChunkName: "component---src-pages-items-object-to-report-success-tsx" */),
  "component---src-pages-items-object-to-report-tsx": () => import("./../../../src/pages/items/object-to-report.tsx" /* webpackChunkName: "component---src-pages-items-object-to-report-tsx" */),
  "component---src-pages-julehjaelpen-index-tsx": () => import("./../../../src/pages/julehjaelpen/index.tsx" /* webpackChunkName: "component---src-pages-julehjaelpen-index-tsx" */),
  "component---src-pages-koeberbeskyttelse-index-tsx": () => import("./../../../src/pages/koeberbeskyttelse/index.tsx" /* webpackChunkName: "component---src-pages-koeberbeskyttelse-index-tsx" */),
  "component---src-pages-plus-index-tsx": () => import("./../../../src/pages/plus/index.tsx" /* webpackChunkName: "component---src-pages-plus-index-tsx" */),
  "component---src-pages-plus-signup-success-tsx": () => import("./../../../src/pages/plus/signup-success.tsx" /* webpackChunkName: "component---src-pages-plus-signup-success-tsx" */),
  "component---src-pages-popuppricelist-index-tsx": () => import("./../../../src/pages/popuppricelist/index.tsx" /* webpackChunkName: "component---src-pages-popuppricelist-index-tsx" */),
  "component---src-pages-receipts-index-tsx": () => import("./../../../src/pages/receipts/index.tsx" /* webpackChunkName: "component---src-pages-receipts-index-tsx" */),
  "component---src-pages-shops-index-tsx": () => import("./../../../src/pages/shops/index.tsx" /* webpackChunkName: "component---src-pages-shops-index-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

